<ng-container *ngIf="state$ | async as state">
  <div class="brand-container">
    <img class="brand-logo" [src]="'./assets/images/branding/' + state.brandLogo" />
  </div>
  <div class="brand-phone">(800) 325-7484</div>
  <mat-nav-list class="list">
    <mbp-sidemenu-item icon="dashboard" route="dashboard">Dashboard</mbp-sidemenu-item>
    <mbp-sidemenu-item
      *ngIf="!state.isCustomer"
      icon="search"
      [route]="['', { outlets: { contractsearch: ['open', NbWindowState.MAXIMIZED] } }]"
      [addActiveClass]="false"
    >
      Contract Search
    </mbp-sidemenu-item>

    <mbp-sidemenu-item *ngIf="!state.isCustomer && state.canDoRater" icon="drive_eta" route="rater"> Rater </mbp-sidemenu-item>

    <mbp-sidemenu-item *ngIf="!state.isCustomer && state.canDoRemittance" icon="monetization_on" route="remittance"
      >Remittance
    </mbp-sidemenu-item>

    <mbp-sidemenu-item *ngIf="!state.isCustomer && state.canDoReporting" icon="pie_chart_outlined" route="reporting">
      Reporting
    </mbp-sidemenu-item>

    <mbp-sidemenu-item
      *ngIf="!state.isCustomer"
      icon="close"
      [route]="['', { outlets: { contractsearch: ['open', NbWindowState.FULL_SCREEN] } }]"
      [addActiveClass]="false"
      >Cancel Quote</mbp-sidemenu-item
    >

    <mbp-sidemenu-item *ngIf="!state.isCustomer" icon="insert_drive_file" route="documents"> Documents </mbp-sidemenu-item>

    <mbp-sidemenu-item *ngIf="state.canDoUserMaintenance" icon="people" route="usermanagement"> User Management </mbp-sidemenu-item>

    <mbp-sidemenu-item *ngIf="state.isMBPIUser" icon="message" route="internalmessages"> Messages </mbp-sidemenu-item>

    <mbp-sidemenu-item *ngIf="state.isAgentOrDealer" icon="note" route="reorder">Reorder Supplies</mbp-sidemenu-item>

    <mbp-sidemenu-item *ngIf="state.isMBPIUser && state.hasAdministratorRole" icon="people" route="usermanagement">
      User Management
    </mbp-sidemenu-item>

    <mbp-sidemenu-item *ngIf="state.isMBPIUser || state.isAllowedClaimsPreAuth" icon="car_repair" route="claims-authorization">
      Claims Authorization
    </mbp-sidemenu-item>

    <mbp-sidemenu-item class="logout" icon="exit_to_app" [externalRoute]="logoutUrl" target="_self">Logout</mbp-sidemenu-item>
  </mat-nav-list>
</ng-container>
