import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '@mbp/environment';
import { NbThemeModule, NbWindowModule } from '@nebular/theme';
import { Store } from '@ngxs/store';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { UserProfileService } from './api/user-profile.service';
import { MbpErrorHandler } from './error-handler';
import { initializeApp } from './init.service';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { HeadersInterceptor } from './interceptors/headers.interceptor';
import { LogRocketService } from './services/log-rocket.service';
import { MediaService } from './services/media-service';
import { VersionCheckService } from './services/version-check.service';
import { StateModule } from './state/state.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

export const AUTH_API_BASE_URL = new InjectionToken<string>('AUTH_API_BASE_URL');
export const PORTAL_API_BASE_URL = new InjectionToken<string>('PORTAL_API_BASE_URL');
export const QUOTE_BASE_URL = new InjectionToken<string>('QUOTE_BASE_URL');
export const NOTIFICATIONS_BASE_URL = new InjectionToken<string>('NOTIFICATIONS_BASE_URL');

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    StateModule,
    FontAwesomeModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbWindowModule.forRoot(),
  ],
  exports: [NbThemeModule, NbWindowModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: ErrorHandler, useClass: MbpErrorHandler },
    // FIXME: This causes performance issues
    // environment.logRocket.on
    //   ? {
    //       provide: NGXS_PLUGINS,
    //       useClass: MbpLogRocketPlugin,
    //       multi: true,
    //     }
    //   : [],
    {
      provide: AUTH_API_BASE_URL,
      useValue: environment.apis.auth,
    },
    {
      provide: PORTAL_API_BASE_URL,
      useValue: environment.apis.portal,
    },
    // {
    //   provide: QUOTE_BASE_URL,
    //   useValue: environment.apis.quote,
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [MediaService, VersionCheckService, LogRocketService, UserProfileService, AUTH_API_BASE_URL, Store, Router],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
