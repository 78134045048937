import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@mbp/core';
import { environment } from '@mbp/environment';
import { NotFoundComponent } from '@mbp/shared';
import { AppPreloadingStrategy } from './app-preloading.strategy';
import { ContractSearchGuard } from './core/guards/contract-search.guard';
import { ContractSearchOutletComponent } from './shared/components/contract-search-outlet/contract-search-outlet.component';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'login', redirectTo: '' },
  { path: 'auth', redirectTo: '' },
  { path: 'user', redirectTo: '' },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((mod) => mod.DashboardModule),
    data: { preload: false },
  },
  {
    path: 'cancelquote',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/cancel-quote/cancel-quote.module').then((mod) => mod.CancelQuoteModule),
    data: { preload: true, delay: 15000 },
  },
  {
    path: 'rater',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/rater/rater.module').then((mod) => mod.RaterModule),
    data: { preload: true, delay: 15000 },
  },
  {
    path: 'internalmessages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/internal-messages/internal-messages.module').then((mod) => mod.InternalMessagesModule),
    data: { preload: false },
  },
  {
    path: 'usermanagement',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/user-management/user-management.module').then((mod) => mod.UserManagementModule),
    data: { preload: false },
  },
  {
    path: 'documents',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/document-repository/document-repository.module').then((mod) => mod.DocumentRepositoryModule),
    data: { preload: true, delay: 15000 },
  },
  {
    path: 'documents/:forInternalUse',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/document-repository/document-repository.module').then((mod) => mod.DocumentRepositoryModule),
    data: { preload: true, delay: 15000 },
  },
  {
    path: 'reporting',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/reporting/reporting.module').then((mod) => mod.ReportingModule),
    data: { preload: true, delay: 15000 },
  },
  {
    path: 'reorder',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/reorder/reorder.module').then((mod) => mod.ReorderModule),
    data: { preload: true, delay: 15000 },
  },
  {
    path: 'dealers',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/dealer-info/dealer-info.module').then((mod) => mod.DealerInfoModule),
    data: { preload: true, delay: 15000 },
  },
  {
    path: 'open/:screenSize',
    component: ContractSearchOutletComponent,
    outlet: 'contractsearch',
    canActivate: [ContractSearchGuard],
  },
  { path: 'landing', loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule) },
  {
    path: 'remittance',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/remittance/remittance.module').then((m) => m.RemittanceModule),
    data: { preload: true, delay: 15000 },
  },
  {
    path: 'claims-authorization',
    loadChildren: () => import('./modules/claims-authorization/claims-authorization.module').then((m) => m.ClaimsAuthorizationModule),
    data: { preload: false },
  },
  { path: '**', component: NotFoundComponent },
];

const extraOptions: ExtraOptions = {
  enableTracing: environment.routing.enableTracing,
  preloadingStrategy: AppPreloadingStrategy,
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
})
export class AppRoutingModule {}
